import './list.scss';
import dataApi from '@/api/system/account.js';
// import role from '@/api/system/role.js';
// import check from '@/api/workbench.js';

export default {
  name: 'account-list',
  components: {},
  data() {
    // 此处自定义校验手机号码js逻辑
    const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    const validatePhone = (rule, value, callback) => {
      console.log(rule);
      if (!value) {
        return callback(new Error('号码不能为空!!'));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error('请输入正确的手机号码'));
        } else {
          callback();
        }
      }, 1000);
    };
    const validatePass = (rule, value, callback) => {
      console.log(rule);
      // console.log(value, this.form.newPassword);
      if (!value) {
        return callback(new Error('重复密码不能为空!!'));
      }
      setTimeout(() => {
        if (value !== this.form.password) {
          callback(new Error('两次密码不一样！！'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      searchData: {},
      tableData: [],
      tbleOption: {
        align: 'center',
        searchMenuPosition: 'left',
        filterBtn: false,
        ...this.$store.getters.tableConfig,
        searchMenuSpan: 9,
        column: [
          {
            // 用户ID   user_id
            label: '用户ID',
            addDisplay: false,
            editDisplay: false,
            hide: true,
            prop: 'user_id'
          },
          {
            // 昵称  nick_name
            label: '昵称 ',
            span: 24,
            rules: [
              {
                required: true,
                message: '请输入昵称',
                trigger: 'blur'
              }
            ],
            prop: 'nick_name'
          },
          {
            label: '账号',
            searchRange: true,
            searchSpan: 5,
            searchLabelWidth: 50,
            search: true,
            span: 12,
            placeholder: '用户名/昵称/手机号/邮箱',
            tip: '账号最多30字符',
            editDisplay: false,
            editDisabled: true,
            maxlength: 30,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: '请输入账号',
                trigger: 'blur'
              }
            ],
            prop: 'login_name'
          },
          {
            label: '密码',
            hide: true,
            searchRange: true,
            searchSpan: 5,
            searchLabelWidth: 50,
            search: true,
            type: 'password',
            span: 12,
            editDisplay: false,
            editDisabled: true,
            maxlength: 30,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: '请输入密码',
                trigger: 'blur'
              }
            ],
            prop: 'password'
          },
          {
            // 手机号码  mobile
            label: '手机号码',
            rules: [{ required: true, validator: validatePhone, trigger: ['blur', 'change'] }],
            prop: 'mobile'
          },
          {
            // 邮箱  email
            label: '邮箱',
            hide: true,
            rules: [
              {
                required: true,
                message: '请输入邮箱',
                trigger: 'blur'
              }
            ],
            prop: 'email'
          },
          {
            label: '关联角色',
            type: 'tree',
            span: 12,
            hide: true,
            display: true,
            dicFlag: true,
            prop: 'role_id',
            props: {
              label: 'label',
              value: 'value'
            },
            multiple: true,
            dicUrl: `${this.$baseUrl}general/get_user_role`,
            dicQuery: {},
            dicMethod: 'post',
            dataType: 'number',
            rules: [
              {
                required: true,
                message: '请选择角色',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '性别',
            hide: true,
            type: 'radio',
            span: 12,
            rules: [
              {
                required: true,
                message: '请选择用户性别',
                trigger: 'blur'
              }
            ],
            value: 2,
            dicData: [
              {
                label: '男',
                value: 0
              },
              {
                label: '女',
                value: 1
              },
              {
                label: '未知',
                value: 2
              }
            ],
            prop: 'sex'
          },
          {
            label: '状态',
            span: 12,
            rules: [
              {
                required: true,
                message: '请选择用户状态',
                trigger: 'blur'
              }
            ],
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '停用',
                value: 0
              }
            ],
            prop: 'status'
          },
          {
            label: '类型',
            span: 12,
            hide: true,
            rules: [
              {
                required: true,
                message: '请输入类型',
                trigger: 'blur'
              }
            ],
            editDisplay: false,
            addDisabled: true,
            prop: 'user_type',
            type: 'radio',
            value: '00',
            dicData: [
              {
                label: '系统用户',
                value: '00'
              },
              {
                label: '商户员工',
                value: '01'
              }
            ]
          },
          {
            // 查询时间状态（2登陆时间 1 添加时间）
            label: '日期',
            searchRange: true,
            search: true,
            hide: true,
            searchSpan: 5,
            // searchLabelWidth: 30,
            addDisplay: false,
            editDisplay: false,
            span: 12,
            labelWidth: 0,
            type: 'select',
            value: 1,
            dicData: [
              {
                label: '添加时间',
                value: 1
              },
              {
                label: '登陆时间',
                value: 2
              }
            ],
            prop: 'date_type'
          },
          {
            // 登录时间 date_time
            // label: '时间',
            labelWidth: 1,
            searchSpan: 5,
            searchLabelWidth: 1,
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            searchRange: true,
            search: true,
            addDisplay: false,
            hide: true,
            editDisplay: false,
            prop: 'date_time'
          },
          {
            // 最后登录IP  login_ip
            label: '最后登录IP',
            addDisplay: false,
            editDisplay: false,
            prop: 'login_ip'
          },
          {
            // 最后登录时间 login_date
            label: '登录时间',
            type: 'date',
            addDisplay: false,
            editDisplay: false,
            prop: 'login_date'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            hide: true,
            editDisplay: false,
            prop: 'create_by'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            type: 'datetimerange',
            valueFormat: 'yyyy-MM-dd hh:mm:ss',
            searchRange: true,
            // search: true,
            hide: true,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            hide: true,
            editDisplay: false,
            prop: 'update_by'
          },
          {
            // 备注 remark
            label: '备注',
            prop: 'remark',
            span: 24,
            type: 'textarea'
          }
        ]
      },
      dialogVisible: false,
      form: {},
      formOp: {
        column: [
          {
            label: '旧密码',
            prop: 'oldpassword',
            type: 'password',
            span: 24,
            rules: [{ required: true, message: '旧密码不能为空', trigger: 'blur' }]
          },
          {
            label: '新密码',
            prop: 'password',
            type: 'password',
            span: 24,
            rules: [{ required: true, message: '新密码不能为空', trigger: 'blur' }]
          },
          {
            label: '确认密码',
            prop: 'newPassword',
            type: 'password',
            span: 24,
            rules: [{ required: true, validator: validatePass, trigger: ['blur', 'change'] }]
          }
        ],
        labelPosition: 'left',
        labelSuffix: '：',
        labelWidth: 100,
        gutter: 0,
        menuSpan: 24,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center'
      },
      account: {},
      tbleType: true
    };
  },
  computed: {},
  created() {

  },
  watch: {
  },
  mounted() {
    this.getRole();
  },
  filters: {},
  methods: {
    selectionChange(e) {
      console.log('多选---------------', e);
    },
    // 重置密码
    reset() {
      const postData = {
        user_id: this.account.user_id,
        type: 2
      };
      dataApi.updatePass.r(postData).then(() => {
        this.$message.success('密码重置已被重置为: 123456');
        this.dialogVisible = false;
      });
    },
    // 修改密码
    editPassword(row) {
      this.account = row;
      this.dialogVisible = true;
    },
    // 修改密码提交
    submitUpPwdForm(e, done) {
      const postData = {
        user_id: this.account.user_id,
        ...e,
        type: 1
      };
      dataApi.updatePass.r(postData).then(() => {
        done();
        this.$message.success('密码修改成功');
        this.dialogVisible = false;
      });
      setTimeout(() => {
        done();
      }, 1000);
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      if (this.searchData.date_time) {
        this.searchData = {
          ...params,
          startdate: params.date_time[0],
          enddate: params.date_time[1]
          // date_type: 1
        };
      }
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    async handleSubmit(form, done, loading) {
      const postData = { ...form };
      console.log('postData', postData);
      dataApi.add.r(postData).then(() => {
        done(form);
        this.getList();
      });
      loading();
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ user_id: form.user_id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 导出
    exportBtn() {
      this.$confirm('是否导出数据?', '提示', {
        confirmButtonText: '导出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.exportFile.r(this.searchData).then((res) => {
          console.log(res);
          this.$message.success('导出成功');
        });
      });
    },
    // 修改
    rowUpdate(form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then((res) => {
        console.log(res);
        done(form);
        loading();
      });
      // done(form);
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      console.log('接口参数', postData);
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
