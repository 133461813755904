<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">

    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @row-save="handleSubmit"
      @row-update="rowUpdate"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selectionChange="selectionChange"
      :page.sync="page"
    >
      <!-- <template slot="searchMenu">
        <el-button size="small" @click="exportBtn" icon="el-icon-sort">导 出</el-button>
      </template> -->
      <template slot="menuLeft">
        <!-- slot-scope="{type,size}"  -->
         <!-- :size="size" :type="type" type="primary" -->
      </template>
      <template slot="menu" scope="{row}">
        <el-button @click="editPassword(row)" icon="el-icon-check" size="small" type="text">修改密码</el-button>
      </template>

    </avue-crud>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="40%">
        <avue-form
            ref="upPwdForm"
            :option="formOp"
            v-model="form"
            @submit="submitUpPwdForm">
            <template slot="menuForm">
                <el-button @click="reset" slot="reference" icon="el-icon-refresh-right" type="warning">直接重置</el-button>
            </template>
        </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
